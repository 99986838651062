import { Observer, useLocalObservable } from "mobx-react";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import gql from "graphql-tag";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  Grid,
  Loading,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import { Page, PageHeader } from "@src/resources/generic/components";
import { RssFeedForm } from "@src/resources/News/components";

const { Text } = Typography;

const RSS_FEED_QUERY = gql`
  query RssFeed($id: ID!) {
    RssFeed(id: $id) {
      id
      url
      provider {
        id
        name
      }
    }
  }
`;

function EditRssFeedPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading } = useQuery({
    queryKey: ["RssFeed"],
    queryFn: async () => {
      const response = await apiClient.graphql(RSS_FEED_QUERY, {
        variables: { id },
      });

      if (response?.RssFeed) {
        const { RssFeed } = response;
        const feedData = {
          url: RssFeed.url,
          provider: RssFeed.provider,
        };
        feed.updateAll(feedData);
        return feedData;
      } else {
        return { error: "error" };
      }
    },
    refetchOnWindowFocus: false,
  });

  const feed = useLocalObservable(() => ({
    url: "",
    provider: null,

    update(property, value) {
      this[property] = value;
    },

    updateAll(value) {
      Object.assign(this, value);
    },

    getSnapshot() {
      return {
        ...JSON.parse(JSON.stringify(this)),
        provider: this.provider.id,
      };
    },

    get valid() {
      const URL = new RegExp(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
      );
      return this.url && this.url.match(URL) && this.provider;
    },
    get invalid() {
      return !this.valid;
    },
  }));

  const deleteRssFeedHandler = async () => {
    try {
      await apiClient.delete(`/news/rssfeed/${id}`);
      notification.success({ message: `The feed has been deleted!` });
      navigate("/news");
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  const updateRssFeedHandler = async () => {
    try {
      await apiClient.patch(`/news/rssfeed/${id}`, feed.getSnapshot());
      notification.success({ message: `The feed has been updated!` });
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <PageHeader iconType="news">Edit RSS feed</PageHeader>
      <Card>
        <Grid.Row gutter={64}>
          <Grid.Col flex={4}>
            <RssFeedForm feed={feed} isEditing={true} />
            <Observer>
              {() => (
                <Button
                  disabled={feed.invalid}
                  mt="lg2"
                  onClick={updateRssFeedHandler}
                  type="primary"
                >
                  Update
                </Button>
              )}
            </Observer>
          </Grid.Col>
          <Grid.Col flex={1}>
            <Text>Actions</Text>
            <Button
              block
              danger
              mt="sm"
              onClick={deleteRssFeedHandler}
              type="primary"
            >
              Delete
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </Page>
  );
}

export { EditRssFeedPage };
