import React from "react";

import { FlexBox } from "../FlexBox/FlexBox";
import { ListControls } from "./ListControls";

function CursorListControls({ show = true, pagination }) {
  if (!show || !pagination) return null;

  function goNextPage() {
    pagination.goNextPage();
  }
  function goPreviousPage() {
    pagination.goPreviousPage();
  }

  function setPageSize(value) {
    pagination.setPageSize(value);
  }

  return (
    <FlexBox justify="flex-end" mt={3}>
      <ListControls
        goNextPage={goNextPage}
        goPreviousPage={goPreviousPage}
        numItems={pagination.pageSize}
        rowOptions={pagination.pageSizeOptions}
        rowText="Rows per page"
        setNumItems={setPageSize}
        {...pagination}
      />
    </FlexBox>
  );
}

export { CursorListControls };
