import { Flex, Select } from "antd";
import React from "react";

import { Button, FormatText, Space } from "@gfw/corvus";

const { Option } = Select;

function ListControls({
  pageInfo,
  itemsPerPageState,
  onNext,
  onPrevious,
  rowOptions,
}) {
  const [itemsPerPage, setItemsPerPage] = itemsPerPageState;

  const { hasNextPage, hasPreviousPage } = pageInfo;
  const enablePrevious = hasPreviousPage;
  const enableNext = hasNextPage;

  return (
    <Flex align-items="center">
      {rowOptions && (
        <Space size="small">
          <FormatText fs="xs">Rows per page</FormatText>
          <Select onSelect={setItemsPerPage} size="sm" value={itemsPerPage}>
            {rowOptions.map((rowOption) => (
              <Option key={rowOption} value={rowOption}>
                {rowOption}
              </Option>
            ))}
          </Select>
        </Space>
      )}
      <Button
        disabled={!enablePrevious}
        icon="arrowLeft"
        iconFs={24}
        onClick={onPrevious}
      />
      <Button
        disabled={!enableNext}
        icon="arrowRight"
        iconFs={24}
        onClick={onNext}
      />
    </Flex>
  );
}

export { ListControls };
