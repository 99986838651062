import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Button, Modal, notification } from "@gfw/corvus";
import { useMutation } from "@tanstack/react-query";

function RemoveUserFromProfileAction({ onActionCompleted, profileOId, user }) {
  const { mutateAsync: removeUser } = useMutation({
    mutationFn: () =>
      apiClient.delete(`/profiles/${profileOId}/users/${user._id}`),
    onSuccess: () => {
      notification.success({
        message: `User ${user.username} was removed from profile`,
      });
      onActionCompleted();
    },
    onError: () => {
      notification.error({
        message: "Oops, something went wrong",
        description: "An error occurred while trying to remove the user",
      });
    },
  });

  return (
    <Modal
      hideFooterBorder
      hideHeaderBorder
      onOk={removeUser}
      title="Remove user from profile"
      trigger={
        <Button icon="unlink" tooltip="Remove a user from profile">
          Remove
        </Button>
      }
    >
      Please note that this will remove the user from this profile
    </Modal>
  );
}

export { RemoveUserFromProfileAction };
