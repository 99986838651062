import { get } from "lodash";
import React from "react";

import { FlexBox } from "../FlexBox";
import { useNextId } from "../../core";

const FormControlContext = React.createContext();

function useFormControl(props) {
  const context = React.useContext(FormControlContext);

  // if we have a context the component is wrapped in <FormControl />
  if (context) {
    return Object.keys(context).reduce((newProps, key) => {
      // props always win over context...
      return {
        ...newProps,
        [key]: get(props, key, context[key]),
      };
    }, {});
  } else {
    return props;
  }
}

// FormControl provides context such as invalid, disabled, and required to form elements
function FormControl({
  name,
  id,
  isReadOnly,
  isDisabled,
  isInvalid,
  ref,
  ...props
}) {
  const _name = useNextId("formcontrol");

  const context = {
    name: name || _name,
    id: name || id || _name,
    isReadOnly,
    isDisabled,
    isInvalid,
  };

  return (
    <FormControlContext.Provider value={context}>
      <FlexBox
        border={false}
        direction="column"
        font-size="xs"
        line-height={1.5}
        ref={ref}
        role="group"
        {...props}
      />
    </FormControlContext.Provider>
  );
}

export { FormControl, useFormControl };
