import { Flex, Statistic, Typography } from "antd";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  Grid,
  Icon,
  Modal,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import { Page, PageHeader, PageTitle } from "@src/resources/generic/components";

const { Text } = Typography;

function StatsPage() {
  const {
    mutateAsync: getStats,
    isLoading: isFetching,
    data: stats,
  } = useMutation({
    mutationFn: async () => await apiClient.get(`/admin/stats`),
    onError: __DEPRECATED__ErrorHandler,
  });

  const { mutateAsync: refreshStats, isLoading: isRefreshing } = useMutation({
    mutationFn: async () => {
      await apiClient.put(`/admin/stats`, {
        timeout: 2000000,
      });
      getStats();
    },
    onSuccess: () => notification.success({ message: `Stats  updated` }),
    onError: __DEPRECATED__ErrorHandler,
  });
  useEffect(() => {
    getStats();
  }, [getStats]);

  return (
    <Page>
      <Flex justify="space-between">
        <PageHeader iconType="queryStats">Stats</PageHeader>
        <PageTitle>
          <Flex align="flex-end" gap="small" vertical>
            <Modal
              hideFooterBorder
              hideHeaderBorder
              onOk={refreshStats}
              title="Update stats"
              trigger={
                <Button
                  icon="refresh"
                  loading={isRefreshing || isFetching}
                  type="primary"
                >
                  Update stats
                </Button>
              }
            >
              <Flex gap="small" vertical>
                <Text>
                  Do you really want to update the stats for all profiles ?
                </Text>
                <Text strong>
                  It might generate performance issue on the app !
                </Text>
              </Flex>
            </Modal>
            {stats?.data?.updatedAt && (
              <Flex align="baseline" gap="small" justify="flex-end">
                <span>Updated at:</span>
                <Text>{stats.data.updatedAt}</Text>
              </Flex>
            )}
          </Flex>
        </PageTitle>
      </Flex>
      <Grid.Row gutter={[16, 16]}>
        <Grid.Col sm={12} xs={24}>
          <Card>
            <Statistic
              prefix={<Icon fs="md" mr="sm" type="users" />}
              title="Profiles"
              value={stats?.data?.profilesCount}
            />
          </Card>
        </Grid.Col>
        <Grid.Col sm={12} xs={24}>
          <Card>
            <Statistic
              prefix={<Icon fs="md" mr="sm" type="users" />}
              title="Users"
              value={stats?.data?.usersCount}
            />
          </Card>
        </Grid.Col>
        <Grid.Col sm={12} xs={24}>
          <Card>
            <Statistic
              prefix={<Icon fs="md" mr="sm" type="campaigns" />}
              title="Pending campaign count with legacy questionnaire requirement(s)"
              value={stats?.data?.pendingLegacyCampaignCount}
            />
          </Card>
        </Grid.Col>
        <Grid.Col sm={12} xs={24}>
          <Card>
            <Statistic
              prefix={<Icon fs="md" mr="sm" type="requests" />}
              title="Pending request count with legacy questionnaire requirement(s)"
              value={stats?.data?.pendingLegacyRequestCount}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page>
  );
}

export default StatsPage;
