import React from "react";

import { Box } from "../Box";
import { ListBoxEvent, useListBox } from "./ListBox";

function getListBoxOptionStyle({ color }) {
  return {
    color: `${color}.500`,
    px: "16px",
    py: "8px",
    outline: "none",
    pseudo: {
      hover: {
        bg: `${color}.100`,
      },
    },
  };
}

function ListBoxOption({
  children,
  value,
  color = "neutral",
  cursor = "pointer",
  ref,
  ...props
}) {
  const { dispatch, onChange } = useListBox();

  const styleProps = getListBoxOptionStyle({
    color: color === "neutral" ? "gray" : color,
  });

  function onClickHandler() {
    dispatch({
      type: ListBoxEvent.OPTION_MOUSE_DOWN,
      value,
    });

    onChange && onChange(value);
  }

  return (
    <Box
      as="li"
      color={color}
      cursor={cursor}
      onClick={onClickHandler}
      ref={ref}
      role="option"
      tabIndex={-1}
      {...styleProps}
      {...props}
    >
      {children}
    </Box>
  );
}

export { ListBoxOption };
