import { createPopper } from "@popperjs/core";
import { Portal } from "reakit/Portal";
import React, { useEffect, useRef, useState } from "react";

import { Box } from "../Box";
import { composeRefs } from "../../core";
import applyMinWidth from "./applyMinWidthModifier";
import maxSize from "./maxSizeModifier";

const overLayProps = {
  "z-index": "overlay",
  "box-shadow": "xl",
};

function Overlay({
  children,
  targetRef,
  isVisible,
  placement = "bottom",
  offset = [0, 0],
  bg = "white",
  border = true,
  ref,
  ...props
}) {
  const [styles, setStyles] = useState({});
  const popper = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    popper.current = createPopper(targetRef?.current, overlayRef?.current, {
      placement: placement,
      strategy: "absolute",
      modifiers: [
        {
          name: "applyStyles",
          enabled: false,
        },
        {
          name: "offset",
          options: {
            offset,
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
        },
        {
          name: "flip",
          enabled: true,
        },
        applyMinWidth,
        {
          ...maxSize,
          options: {
            padding: 8,
          },
        },
        {
          name: "applyMaxSize",
          enabled: true,
          phase: "beforeWrite",
          requires: ["maxSize"],
          fn({ state }) {
            const { height } = state.modifiersData.maxSize;
            state.styles.popper.maxHeight = `${height}px`;
            state.styles.popper.overflowY = "auto";
          },
        },
        {
          name: "updateState",
          phase: "write",
          enabled: true,
          fn({ state }) {
            setStyles({
              ...state.styles.popper,
            });
          },
        },
      ],
    });
    return () => {
      if (popper.current) {
        popper.current.destroy?.();
        popper.current = null;
      }
    };
  }, [targetRef, overlayRef, placement, offset]);

  useEffect(() => {
    if (popper.current) {
      popper.current.forceUpdate();
    }
  }, [isVisible]);

  return (
    <Portal>
      <Box
        ref={composeRefs(overlayRef, ref)}
        {...props}
        {...overLayProps}
        bg={bg}
        border={border}
        offset={offset}
        placement={placement}
        style={styles}
        visibility={isVisible ? "visible" : "hidden"}
      >
        {children}
      </Box>
    </Portal>
  );
}

export { Overlay };
