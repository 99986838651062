import React from "react";

import { Box } from "../Box";

function FlexBox({ align, justify, direction, wrap, variant, ref, ...props }) {
  return (
    <Box
      align-items={align}
      display={variant === "inline" ? "inline-flex" : "flex"}
      flex-direction={direction}
      flex-wrap={wrap}
      justify-content={justify}
      ref={ref}
      {...props}
    />
  );
}

FlexBox.Item = Box;

export { FlexBox };
