import { omit } from "lodash";
import React from "react";

import { Box } from "../Box";
import { createAndUseContext } from "../../core";
import { Stack } from "../Stack";

const [CheckboxGroupProvider, useCheckboxGroup] = createAndUseContext({
  name: "CheckboxGroup",
  required: false,
});

function CheckboxGroup({
  isDisabled,
  isReadOnly,
  onChange,
  defaultValues = [],
  children,
  as: Component = Stack,
  spacing = "none",
  ref,
  ...props
}) {
  const [values, setValues] = React.useState(defaultValues);
  const { current: isControlled } = React.useRef("values" in props);

  const getValues = () => (isControlled ? props.values : values);

  const onGroupChange = (event) => {
    const { checked, value } = event.target;
    let newValues;
    if (checked) {
      // add the value
      newValues = [...getValues(), value];
    } else {
      // remove the value
      newValues = getValues().filter((val) => val !== value);
    }

    if (!isControlled) {
      setValues(newValues);
    }

    onChange?.(newValues);
  };

  const context = {
    isDisabled,
    isReadOnly,
    values: getValues(),
    onChange: onGroupChange,
  };

  return (
    <CheckboxGroupProvider value={context}>
      <Box
        as={Component}
        ref={ref}
        role="group"
        spacing={spacing}
        {...omit(props, "values")}
      >
        {children}
      </Box>
    </CheckboxGroupProvider>
  );
}

export { CheckboxGroup, useCheckboxGroup };
