import { omit } from "lodash";
import React from "react";

import { Box } from "../Box";
import { generateNextId, getValidReactChildren } from "../../core";
import { Stack } from "../Stack";

function RadioGroup({
  isDisabled,
  isReadOnly,
  onChange,
  defaultValue = "",
  name,
  children,
  as: Component = Stack,
  ref,
  ...props
}) {
  const [value, setValue] = React.useState(defaultValue);
  const { current: isControlled } = React.useRef("value" in props);

  const getValue = () => (isControlled ? props.value : value);
  // all radios in a group must use the same name
  const getName = () => name || `radio--${generateNextId()}`;

  const onGroupChange = (event) => {
    if (!isControlled) {
      setValue(event.target.value);
    }

    onChange?.(event);
  };

  const radios = getValidReactChildren(children).map((radio) => {
    return React.cloneElement(radio, {
      isDisabled: isDisabled || radio.props.isDisabled,
      isReadOnly: isReadOnly || radio.props.isReadOnly,
      isChecked: radio.props.value === getValue(),
      onChange: onGroupChange,
      name: getName(),
    });
  });

  return (
    <Box
      as={Component}
      ref={ref}
      role="radiogroup"
      spacing="none"
      {...omit(props, "value")}
    >
      {radios}
    </Box>
  );
}

export { RadioGroup };
