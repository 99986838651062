import { Card, Flex } from "antd";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import saveAs from "file-saver";

import { __DEPRECATED__ErrorHandler, Button } from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { datetime, getBrandInfo } from "@gfw/core";

import { Page, PageHeader } from "@src/resources/generic/components";

import ProfilesActiveRequests from "./ProfilesActiveRequests";

function RequestsPage() {
  const { mutateAsync: exportRequests, isLoading } = useMutation({
    mutationFn: () => {
      return apiClient.get(`/admin/requests/export`, {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      });
    },

    onSuccess: (response) => {
      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });

      saveAs(
        file,
        `${getBrandInfo().NAME_SHORT}-requests--${datetime.iso8601Date()}.xlsx`,
        { autoBom: true },
      );
    },
    onError: __DEPRECATED__ErrorHandler,
  });

  return (
    <Page>
      <Flex align="center" justify="space-between">
        <PageHeader iconType="requests">Requests</PageHeader>
        <Button
          icon="download"
          loading={isLoading}
          onClick={exportRequests}
          type="primary"
        >
          Export
        </Button>
      </Flex>
      <Card>
        <ProfilesActiveRequests />
      </Card>
    </Page>
  );
}

export default RequestsPage;
