import { Select, Space } from "antd";
import React from "react";

import { Button } from "../Button/Button";
import { FlexBox } from "../FlexBox/FlexBox";
import { Text } from "../Text/Text";

const { Option } = Select;

function ListControls(props) {
  const {
    hasNextPage,
    hasPreviousPage,
    goNextPage,
    goPreviousPage,
    setNumItems,
    numItems,
    rowOptions,
    rowText,
    isPreviousData,
  } = props;

  function onSelectClick(option) {
    setNumItems(option);
  }

  function onPreviousClick() {
    goPreviousPage();
  }
  function onNextClick() {
    goNextPage();
  }

  const text = rowText || "Rows per page";

  return (
    <FlexBox align-items="center">
      {rowOptions && (
        <Space size="small">
          <Text fs={1}>{text}</Text>
          <Select onSelect={onSelectClick} size="sm" value={numItems}>
            {rowOptions.map((rowOption) => (
              <Option key={rowOption} value={rowOption}>
                {rowOption}
              </Option>
            ))}
          </Select>
        </Space>
      )}
      <Button
        disabled={!hasPreviousPage || isPreviousData}
        icon="caret-left"
        iconFs={24}
        onClick={onPreviousClick}
      />
      <Button
        disabled={!hasNextPage || isPreviousData}
        icon="caret-right"
        iconFs={24}
        onClick={onNextClick}
      />
    </FlexBox>
  );
}

export { ListControls };
