import React from "react";

import { Text } from "../Text";

function FormControlText({ children, ref, ...props }) {
  return (
    <Text
      align="center"
      as="span"
      color="gray.500"
      display="inline-flex"
      mt="4px"
      ref={ref}
      {...props}
    >
      {children}
    </Text>
  );
}

export { FormControlText };
