import { get, has, isBoolean, isNil } from "lodash";
import { getColor } from "../../color";

// https://tailwindcss.com/docs/box-shadow/#app

// ref for box-shadows which look like borders (https://makandracards.com/makandra/12019-css-emulate-borders-with-inset-box-shadows)
export const BOX_SHADOWS = {
  none: "none",
  // TODO: we could probably make this way more sofisticated but for now this is the only use case
  "border-bottom": `inset 0 -1px 0 0 ${getColor("border")}`,
  xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
};

const isScaleValue = (value) => has(BOX_SHADOWS, value);
export function mapPropsToBoxShadow(props) {
  let value = get(props, "box-shadow");

  if (!isNil(value)) {
    if (isBoolean(value)) {
      value = value ? "default" : "none";
    }
    return (
      value && {
        "box-shadow": getBoxShadow(value),
      }
    );
  }
}

export function getBoxShadow(shadow) {
  if (isScaleValue(shadow)) {
    return BOX_SHADOWS[shadow];
  } else {
    return shadow;
  }
}
