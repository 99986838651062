import React from "react";

import { Box } from "../../../Box";
import { Checkbox } from "../../../Checkbox";
import { Field } from "../../../Field";
import { FormControl } from "../../../FormControl";
import { MultiSelect } from "../../../Select";
import { useCheckboxGroup } from "../../../CheckboxGroup";

import { Question } from "..";
import { StyledFlex } from "../../questionnaire.styles";
import { Text } from "../../../Text";
import { toHashCode } from "@gfw/core";
import { useQuestionnaireQuestion } from "../Questionnaire";

/* --NOTE--
    what's happening here is that a choice can have questions that should show if that choice is "checked" (picked)
    A ChoiceCheckBox is a wrapper around a Checkbox with some logic that  shows/hides the children (questions).
  */
function ChoiceCheckbox({ choice, ...props }) {
  const questions = choice.questions || [];
  const group = useCheckboxGroup();

  const isSelected = React.useMemo(() => {
    return group?.values?.includes?.(choice.value);
  }, [choice.value, group.values]);

  return (
    <Box {...props}>
      <Checkbox value={choice.value}>{choice.label || choice.value}</Checkbox>
      {questions.length > 0 && isSelected && (
        <StyledFlex gap="middle" vertical>
          {questions.map(({ key, ...question }) => (
            <Question id={key} key={key} {...question} />
          ))}
        </StyledFlex>
      )}
    </Box>
  );
}

function CheckboxGroupField({
  label,
  subtitle,
  choices,
  helpText,
  questions,
  ...props
}) {
  const question = useQuestionnaireQuestion(props);

  const shouldShowMoreQuestions = React.useMemo(() => {
    if (Array.isArray(question.answer) && question.answer.length > 0) {
      return questions.length > 0;
    } else {
      return false;
    }
  }, [question.answer, questions]);

  return (
    <React.Fragment>
      <FormControl {...props} as="fieldset">
        {helpText ? (
          <>
            {subtitle && (
              <FormControl.Text color="text.secondary" fw="bold">
                {subtitle}
              </FormControl.Text>
            )}
            <FormControl.Label>{label}</FormControl.Label>
            <FormControl.Text mb="sm" mt="0">
              {helpText}
            </FormControl.Text>
          </>
        ) : (
          <>
            {subtitle && (
              <FormControl.Text color="text.secondary" fw="bold">
                {subtitle}
              </FormControl.Text>
            )}
            <FormControl.Label mb="sm">{label}</FormControl.Label>
          </>
        )}
        <Checkbox.Group
          defaultValues={question.answer}
          isDisabled={question.isReadOnly}
          onChange={(newValues) => question.onAnswerUpdated(newValues)}
        >
          {choices.map((choice, index) => (
            <ChoiceCheckbox
              choice={choice}
              key={`${toHashCode(choice.value)}--${index}`}
            />
          ))}
        </Checkbox.Group>
      </FormControl>
      {shouldShowMoreQuestions && (
        <StyledFlex gap="middle" vertical>
          {questions.map(({ key, ...question }) => (
            <Question id={key} key={key} {...question} />
          ))}
        </StyledFlex>
      )}
    </React.Fragment>
  );
}

function MultipleSelectField({
  label,
  subtitle,
  choices,
  questions,
  helpText,
  ...props
}) {
  const question = useQuestionnaireQuestion(props);

  const shouldShowMoreQuestions = React.useMemo(() => {
    if (Array.isArray(question.answer) && question.answer.length > 0) {
      return questions.length > 0;
    } else {
      return false;
    }
  }, [question.answer, questions]);

  return (
    <React.Fragment>
      {question.isReadOnly ? (
        <Box>
          {subtitle && (
            <Text color="text.secondary" fw="bold">
              {subtitle}
            </Text>
          )}
          <Field label={label}>{(question.answer || ["-"]).join(", ")}</Field>
        </Box>
      ) : (
        <FormControl {...props}>
          {helpText ? (
            <>
              {subtitle && (
                <FormControl.Text color="text.secondary" fw="bold">
                  {subtitle}
                </FormControl.Text>
              )}
              <FormControl.Label>{label}</FormControl.Label>
              <FormControl.Text mb="xs" mt="0">
                {helpText}
              </FormControl.Text>
            </>
          ) : (
            <>
              {subtitle && (
                <FormControl.Text color="text.secondary" fw="bold">
                  {subtitle}
                </FormControl.Text>
              )}
              <FormControl.Label mb="xs">{label}</FormControl.Label>
            </>
          )}
          <MultiSelect
            defaultValue={question.answer}
            onSelect={(newAnswer) => question.onAnswerUpdated(newAnswer)}
            placeholder="Select any that apply..."
            size="lg"
          >
            {choices.map((choice, index) => (
              <MultiSelect.Option
                key={`${toHashCode(choice.value)}--${index}`}
                value={choice.value}
              >
                {choice.label || choice.value}
              </MultiSelect.Option>
            ))}
          </MultiSelect>
        </FormControl>
      )}
      {shouldShowMoreQuestions && (
        <StyledFlex gap="middle" vertical>
          {questions.map(({ key, ...question }) => (
            <Question id={key} key={key} {...question} />
          ))}
        </StyledFlex>
      )}
    </React.Fragment>
  );
}

function MultipleChoiceField({ choices, threshold = 6, ...props }) {
  if (choices.length <= threshold) {
    return <CheckboxGroupField choices={choices} {...props} />;
  } else {
    return <MultipleSelectField choices={choices} {...props} />;
  }
}

export default MultipleChoiceField;
