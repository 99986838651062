import { Flex, Table, Tag } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import saveAs from "file-saver";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  FONT_WEIGHTS,
  Loading,
  NavigateLink,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { PROFILE_STATE, profileStateToString, ProfileTypes } from "@gfw/core";
import dayjs from "dayjs";

const StyledName = styled.div`
  font-weight: ${FONT_WEIGHTS.bold};
`;

function ProfileCounterparties({ profile }) {
  const { data: counterparties, isLoading } = useQuery({
    queryKey: ["profileCounterparties", profile._id],
    queryFn: async () => {
      const response = await apiClient.get(
        `/profiles/${profile._id}/admin/counterparties`,
      );
      return response.data;
    },
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: downloadCounterparties, isLoading: isDownloading } =
    useMutation({
      mutationFn: () => {
        return apiClient.get(`/admin/${profile.profileId}/counterparties`, {
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "blob",
        });
      },
      onSuccess: (response) => {
        const file = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(file, `${profile.name} - Counterparties.xlsx`, {
          autoBom: true,
        });
      },
      onError: __DEPRECATED__ErrorHandler,
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, profile) => (
        <NavigateLink noPadding to={`/profiles/${profile?._id}/details`}>
          <StyledName>{name}</StyledName>
        </NavigateLink>
      ),
    },
    {
      title: "Type",
      dataIndex: "types",
      key: "types",
      render: (types) => {
        if (types.length < 3) {
          return ProfileTypes.getNamesFromIds(types).join(", ");
        }
        return `${ProfileTypes.getNamesFromIds(types.slice(0, 2)).join(
          ", ",
        )} and ${types.slice(2).length} more`;
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state) => {
        //unknown state
        let color = "grey";
        let label = profileStateToString(state);
        if (state === PROFILE_STATE.DRAFT) {
          color = "blue";
        } else if (state === PROFILE_STATE.PROPOSED) {
          color = "purple";
        } else if (state === PROFILE_STATE.INACTIVE) {
          color = "red";
        } else if (state === PROFILE_STATE.OFFICIAL) {
          color = "green";
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      title: "Added at",
      dataIndex: "addedAt",
      key: "addedAt",
      render: (addedAt) => dayjs(addedAt).format("DD/MM/YYYY HH:mm"),
    },
  ];

  return (
    <Flex gap={16} vertical>
      <Flex justify="flex-end">
        <Button
          icon="download"
          loading={isDownloading}
          onClick={downloadCounterparties}
          type="primary"
        >
          Download Counterparties
        </Button>
      </Flex>
      <div>
        {isLoading && <Loading size="small" />}
        <Table
          columns={columns}
          dataSource={counterparties}
          loading={isLoading}
          rowKey={(item) => item._id}
          rowSelection={false}
          size="middle"
        />
      </div>
    </Flex>
  );
}

export default ProfileCounterparties;
