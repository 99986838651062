import React from "react";

import { Overlay } from "../Overlay";
import { useComposedRef } from "../../core";
import { useListBox } from "./ListBox";

function ListBoxOverlay({
  border = { color: "#3d9094" },
  placement = "bottom-start",
  offset = [0, 2],
  ref,
  ...props
}) {
  const {
    refs: { overlay: overlayRef, button: buttonRef },
    isExpanded,
  } = useListBox();
  let composeRef = useComposedRef(overlayRef, ref);

  return (
    <Overlay
      border={border}
      isVisible={isExpanded}
      offset={offset}
      placement={placement}
      ref={composeRef}
      targetRef={buttonRef}
      {...props}
    />
  );
}

export { ListBoxOverlay };
