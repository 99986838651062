import { Route, Routes } from "react-router";
import React from "react";

import BannerListPage from "./BannerListPage";
import BannerPage from "./BannerPage";

// This is the root module for all the user routes (e.g. a route handler for /banner)
function Index() {
  return (
    <Routes>
      <Route element={<BannerPage />} path="new" />
      <Route element={<BannerPage />} path=":id/*" />
      <Route element={<BannerListPage />} path="*" />
    </Routes>
  );
}

export default Index;
