import { Table } from "antd";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Loading,
  NavigateLink,
  notification,
  Space,
} from "@gfw/corvus";
import { apiClient, selectorUsersByOId } from "@gfw/backend-connector";
import { USER_STATUS } from "@gfw/core";

const keysToRender = [
  "firstName",
  "lastName",
  "username",
  "terms",
  "status",
  "createdAt",
  "invitedBy",
  "lastConnectionAt",
  "_id",
  "userId",
  "isAdmin",
];

const convertUserAsTable = (user) => {
  if (!user) return [];
  const data = [];

  keysToRender.forEach((key) => {
    if (key === "terms") {
      const value = user.terms?.acceptedAt
        ? `Accepted, at ${user.terms.acceptedAt}`
        : `Not Accepted`;
      data.push({ key, value });
    } else if (key === "invitedBy") {
      if (user.invitedBy) {
        const displayRoleLabel = user.invitedBy.isGFWAdmin ? " (admin)" : "";
        data.push({ key, value: `${user.invitedBy.email}${displayRoleLabel}` });
      }
    } else if (key === "isAdmin") {
      if (user.isAdmin) {
        data.push({ key, value: "true" });
      }
    } else {
      data.push({ key, value: `${user[key]}` });
    }
  });
  return data;
};

function UserDetail({ fetchUser }) {
  const { id } = useParams();
  const user = useSelector(selectorUsersByOId(id));

  const { mutateAsync: forceLogout, isLoading: isForceLogoutLoading } =
    useMutation({
      mutationFn: async () => {
        await apiClient.post(`/users/${id}/logout`);
      },
      onSuccess: () =>
        notification.success({ message: "User is not connected anymore" }),
      onError: __DEPRECATED__ErrorHandler,
    });

  const { mutateAsync: activateUser, isLoading: isActivateUserLoading } =
    useMutation({
      mutationFn: async () => {
        await apiClient.put(`/users/${id}/activate`);
      },
      onSuccess: () => {
        notification.success({ message: "User has been activated" });
        fetchUser?.(id);
      },
      onError: __DEPRECATED__ErrorHandler,
    });

  const { mutateAsync: deactivateUser, isLoading: isDeactivateUserLoading } =
    useMutation({
      mutationFn: async () => {
        await apiClient.put(`/users/${id}/deactivate`);
      },
      onSuccess: () => {
        notification.success({ message: "User has been deactivated" });
        fetchUser?.(id);
      },
      onError: __DEPRECATED__ErrorHandler,
    });

  const antdColumns = [
    {
      title: "key",
      dataIndex: "key",
      key: "key",
    },

    {
      title: "value",
      dataIndex: "value",
      key: "value",
    },
  ];

  if (!user || user.isLoading) return <Loading />;

  return (
    <>
      <Space>
        <NavigateLink icon="edit" noPadding to={`/users/${id}/edit`}>
          Edit
        </NavigateLink>
        {user.status === USER_STATUS.ACTIVE ? (
          <Button
            confirmTitle="It will deactivate the user, are you sure?"
            danger
            icon="deactivateUser"
            loading={isDeactivateUserLoading}
            noPadding
            onClick={deactivateUser}
            type="link"
          >
            Deactivate user
          </Button>
        ) : (
          <Button
            confirmTitle="It will activate the user, are you sure?"
            danger
            icon="activateUser"
            loading={isActivateUserLoading}
            noPadding
            onClick={activateUser}
            type="link"
          >
            Activate user
          </Button>
        )}
        <Button
          confirmTitle="It will logout the user, are you sure?"
          danger
          icon="logout"
          loading={isForceLogoutLoading}
          noPadding
          onClick={forceLogout}
          type="link"
        >
          Force user to logout
        </Button>
      </Space>
      <>
        <Table
          columns={antdColumns}
          dataSource={convertUserAsTable(user)}
          pagination={false}
          rowKey={(item) => item.key}
        />
      </>
    </>
  );
}

export default UserDetail;
