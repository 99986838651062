import { Input, Select } from "antd";
import React from "react";
import styled from "styled-components";

import { apiClient } from "@gfw/backend-connector";
import { Button, Icon, Modal, notification, Space } from "@gfw/corvus";
import { getUserRoles, PROFILE_STATE, USER_ROLE } from "@gfw/core";
import { useMutation } from "@tanstack/react-query";

const StyledSelect = styled(Select)`
  width: 130px;
`;

const StyledInput = styled(Input)`
  width: 512px;
`;

function InviteUserAction({ onActionCompleted, profile }) {
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState(USER_ROLE.FULL);

  // just tells the dialog that this is the element/ref that should have the initial focus when it opens
  const initialFocusRef = React.useRef();

  const { mutateAsync: sendInvite } = useMutation({
    mutationFn: () =>
      apiClient.post("/invites", {
        email,
        profileId: profile.profileId,
        role,
      }),
    onSuccess: () => {
      notification.success({
        message: "The invite has been sent!",
        description: `The invite was sent to ${email}`,
      });
      onActionCompleted();
    },
    onError: (e) => {
      switch (e.response.status) {
        case 409:
          return notification.error({
            message: "Oops, something went wrong",
            description:
              "This email address already has an account. Add the user instead.",
          });
        case 400:
          return notification.error({
            message: "Oops, something went wrong",
            description: "That's not a valid email.",
          });
        default:
          return notification.error({
            message: "Oops, something went wrong",
            description:
              "Sorry, but your invite could not be sent due to an unknown error.",
          });
      }
    },
  });

  const roles =
    profile?.users?.length === 0 ? [USER_ROLE.FULL] : getUserRoles();

  return (
    <Modal
      footer={null}
      title="Create an invite to this profile"
      trigger={
        <Button disabled={profile?.state === PROFILE_STATE.INACTIVE}>
          Invite New User
        </Button>
      }
      width="60%"
    >
      <Space>
        <StyledInput
          name="email"
          onChange={(event) => setEmail(event.target.value)}
          placeholder="Enter the email of the user you wish to invite..."
          prefix={<Icon fs="lg" type="email" />}
          ref={initialFocusRef}
          value={email}
        />
        <StyledSelect onSelect={setRole} value={role}>
          {roles.map((role) => (
            <Select.Option key={role} value={role}>
              {role}
            </Select.Option>
          ))}
        </StyledSelect>
        <Button onClick={sendInvite} type="primary">
          Send
        </Button>
      </Space>
    </Modal>
  );
}

export { InviteUserAction };
