import React from "react";

import { Icon, Input, InputGroup, ListBox } from "../../components";

function useSelect({ onSelect, value, defaultValue }) {
  const [selection, updateSelection] = React.useState(value || defaultValue);

  const onSelectionChange = React.useCallback(
    (newValue) => {
      updateSelection(newValue);
      onSelect && onSelect(newValue);
    },
    [onSelect],
  );

  return React.useMemo(
    () => ({
      selection,
      onSelectionChange,
    }),
    [onSelectionChange, selection],
  );
}

function SelectInput({ children, size = "md", width, ref, ...props }) {
  return (
    <InputGroup
      cursor="pointer"
      height="auto"
      max-width="fit-content"
      min-height="32px"
      min-width={width || size}
      outline="none"
      ref={ref}
      variant="outline"
      {...props}
    >
      <Input as="div">{children}</Input>
      <Icon fs="1.2em" mr="8px">
        caret-down
      </Icon>
    </InputGroup>
  );
}

function Select({
  children,
  placeholder,
  onSelect,
  optionToString = (v) => String(v),
  onBlur,
  size,
  value,
  defaultValue,
  ref,
  ...props
}) {
  const { onSelectionChange, selection } = useSelect({
    onSelect,
    value,
    defaultValue,
  });
  return (
    <ListBox onChange={onSelectionChange} value={selection}>
      <ListBox.Button
        as={SelectInput}
        onBlur={onBlur}
        ref={ref}
        size={size}
        {...props}
      >
        {selection || selection === 0 ? optionToString(selection) : placeholder}
      </ListBox.Button>
      <ListBox.Overlay>
        <ListBox.List>{children}</ListBox.List>
      </ListBox.Overlay>
    </ListBox>
  );
}

Select.Option = ListBox.Option;

export { Select };
