import React from "react";

import { Box } from "../Box";
import { Button } from "../Button";
import { ListBoxEvent, useListBox } from "./ListBox";
import { useComposedRef } from "../../core";

function ListBoxButton({ as = Button, children, ref, ...props }) {
  const {
    dispatch,
    isExpanded,
    isDisabled,
    state,
    refs: { button: buttonRef },
  } = useListBox();
  const ComposeRef = useComposedRef(buttonRef, ref);

  let label = React.useMemo(() => {
    // If the button has children, we just render them as the label
    if (children) {
      return children;
    } else {
      return String(state.context.value);
    }
  }, [children, state.context.value]);

  function onClickHandler() {
    dispatch({
      type: ListBoxEvent.BUTTON_MOUSE_DOWN,
    });
  }

  return (
    <Box
      aria-expanded={isExpanded}
      aria-haspopup="listbox"
      as={as}
      onClick={onClickHandler}
      ref={ComposeRef}
      role="button"
      tabIndex={isDisabled ? -1 : 0}
      {...props}
    >
      {label}
    </Box>
  );
}

export { ListBoxButton };
