import { get } from "lodash";
import Color from "color";
import colors from "../colors";
import warning from "tiny-warning";

export function darken(color, ratio) {
  if (color === "bg") {
    return ({ bg }) => {
      return darken(bg, ratio);
    };
  }

  return getColor(`${color} darken-${ratio}`);
}

export function mapPropsToColor({ color }) {
  return (
    color && {
      color: getColor(color),
    }
  );
}

export function mapPropsToBgColor({ bg }) {
  return (
    bg && {
      "background-color": getColor(bg),
    }
  );
}

function addVariation(variation, color) {
  const [colorFn, amount] = variation.split("-");

  switch (colorFn.toLowerCase()) {
    case "opacify":
      return color.alpha(parseFloat(amount));
    case "fade":
      return color.fade(parseFloat(amount));
    case "darken":
      return color.darken(parseFloat(amount));
    case "lighten":
      return color.lighten(parseFloat(amount));
    case "alpha":
      return color.alpha(parseFloat(amount));
    case "text":
      if (color.isDark()) {
        return Color(colors.text.on.dark);
      } else {
        return Color(colors.text.on.light);
      }
    default:
      warning(
        true,
        `[@gfw/orion] Trying to use color variation ("${colorFn}") but we don't have support for that.`,
      );
      return color;
  }
}

export function getColor(value) {
  if (value.startsWith("inherit") || value.startsWith("current")) {
    return colors[value];
  }

  let [color, ...variations] = value.split(" ");

  if (variations.length > 0) {
    return variations
      .reduce(
        (previous, current) => {
          return addVariation(current, previous);
        },
        Color(get(colors, color, color)),
      )
      .string(0);
  } else {
    return get(colors, color, color);
  }
}
