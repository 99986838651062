import { Route, Routes } from "react-router";
import React from "react";

import UserPage from "./UserPage";
import UserStatsPage from "./UserStatsPage";

// This is the root module for all the user routes (e.g. a route handler for /users)
function Index() {
  return (
    <Routes>
      <Route element={<UserPage />} path=":id/*" />
      <Route element={<UserStatsPage />} path="*" />
    </Routes>
  );
}

export default Index;
