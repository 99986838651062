import React from "react";

import { Icon } from "../../components";
import { Input, InputGroup } from "../Input";

function DateInput({ ref, ...props }) {
  return (
    <InputGroup size="sm">
      <Icon fs="1.2x">calendar</Icon>
      <Input placeholder="YYYY-MM-DD" ref={ref} type="text" {...props} />
    </InputGroup>
  );
}

export { DateInput };
