import React from "react";

import { Box } from "../../../Box";
import { Checkbox } from "../../../Checkbox";
import { Question } from "..";
import { StyledFlex } from "../../questionnaire.styles";
import { useQuestionnaireQuestion } from "../Questionnaire";

/* NOTE:
  This is a bit of a hack to accommodate for the fact the these questions are stored in the DB like this:

  typeOfActivitiesExecutionOnly: ["Execution only"]

  
  Example above for a question with key 'typeOfActivitiesExecutionOnly'

  It's stored as an array with 1 value...being the label of this question.
  This basically means that if the label of the question is in that array this question is "selected" or TRUE
  the answer in the DB can be:
    1. ["value"]
    2. ""
    3. not there at all (null)

  I know...super weird...guess some patched legacy stuff that went bad??...
  Anyway, we have to accommodate for this weirdness in this component, so don't blame me too much. 
*/

function BooleanQuestion({ label, questions = [], ...props }) {
  const question = useQuestionnaireQuestion(props);

  const [value, setValue] = React.useState(() => {
    if (Array.isArray(question.answer)) {
      return question.answer;
    } else {
      return [];
    }
  });
  const isChecked = React.useMemo(() => value.includes(label), [label, value]);

  const onChangeHandler = (event) => {
    const { checked, value } = event.target;

    if (checked) {
      setValue([value]);
    } else {
      setValue([]);
    }
  };

  const onBlurHandler = () => {
    if (isChecked) {
      question.onAnswerUpdated(value);
    } else {
      question.onAnswerUpdated("");
    }
  };

  return (
    <Box {...props}>
      <Checkbox
        defaultIsChecked={isChecked}
        isDisabled={question.isReadOnly}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        value={label}
      >
        {label}
      </Checkbox>
      {isChecked && questions.length > 0 && (
        <StyledFlex gap="middle" vertical>
          {questions.map(({ key, ...question }) => (
            <Question id={key} key={key} {...question} />
          ))}
        </StyledFlex>
      )}
    </Box>
  );
}

export default BooleanQuestion;
