import React from "react";

import { Input } from "../Input";

const defaultProps = {
  py: 2,
  "line-height": "short",
  "min-height": "5rem",
  "max-width": "full",
};

function TextArea({ resize = "vertical", ref, ...props }) {
  return (
    <Input
      as="textarea"
      ref={ref}
      style={{ resize }}
      {...defaultProps}
      {...props}
    />
  );
}

export { TextArea };
