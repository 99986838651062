import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  MailSubscriptions,
  notification,
} from "@gfw/corvus";
import {
  callbackManager,
  selectorUsersByUserOIdWithProfiles,
  userSetMailSubscription,
} from "@gfw/backend-connector";
import { Select } from "antd";

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const Info = styled.div`
  margin-bottom: 20px;
`;

export default function UserSubscriptions() {
  const [loading, setLoading] = useState({});
  const [profile, setProfile] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectorUsersByUserOIdWithProfiles(id));

  useEffect(() => {
    if (user?.profiles?.length) {
      if (!profile) {
        setProfile(user.profiles[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profiles]);

  const callbackUserSubscriptionId = callbackManager.add({
    onSuccess: ({ subscriptionType }) => {
      setLoading((state) => ({ ...state, [subscriptionType]: false }));
      notification.success({ message: "Preference updated" });
    },
    onError: ({ subscriptionType }) => {
      setLoading((state) => ({ ...state, [subscriptionType]: false }));
      notification.error({
        message: "Oops, something went wrong",
      });
    },
  });

  if (!profile)
    return (
      <>
        <p>No profile associated to this account.</p>
        <p>Subscriptions are linked to profile/account relation</p>
      </>
    );

  const setSubscription = (subscriptionType, value) => {
    dispatch(
      userSetMailSubscription({
        subscriptionType,
        userOId: user._id,
        value,
        profileOId: profile?._id,
        callbackId: callbackUserSubscriptionId,
      }),
    );
    setLoading((state) => ({ ...state, [subscriptionType]: true }));
  };

  return (
    <>
      <Info>
        Update the subscriptions for this user. Please ensure that they have
        approved the modification.
      </Info>
      <Title>
        Subcriptions related to{" "}
        <Select
          defaultValue={profile?.name}
          disabled={user?.profileUsers?.length < 2}
          onChange={(profileOIdSelected) => {
            setProfile(
              user?.profiles?.find((p) => p._id === profileOIdSelected),
            );
          }}
          options={user?.profiles?.map((p) => {
            return {
              value: p._id,
              label: p.name,
            };
          })}
        />
      </Title>
      <MailSubscriptions
        loading={loading}
        setSubscription={setSubscription}
        subscriptions={
          user?.profileUsers?.find((p) => p.profileOId === profile._id)
            ?.subscriptions
        }
      />
    </>
  );
}
