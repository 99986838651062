import { Table } from "antd";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import styled from "styled-components";

import { apiClient } from "@gfw/backend-connector";
import { BASE_SORT_COLUMN_DESCEND, SORT_ORDER } from "@gfw/core";
import { getFontWeight, NavigateLink } from "@gfw/corvus";

const StyledP = styled.p`
  font-weight: ${getFontWeight("bold")};
`;

function ProfilesActiveRequests() {
  const {
    mutateAsync: getActiveRequestsCount,
    isLoading,
    data,
  } = useMutation({
    mutationFn: async () => {
      const response = await apiClient.get("/admin/active-requests-count");
      return response.data;
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, profile) => (
        <NavigateLink noPadding to={`/profiles/${profile?.profileId}/details`}>
          <StyledP>{name}</StyledP>
        </NavigateLink>
      ),
    },
    {
      title: "Access Rights Requests",
      dataIndex: "activeAccessRightsRequestCount",
      key: "activeAccessRightsRequestCount",
    },
    {
      title: "DD Requests",
      dataIndex: "activeDueDiligenceRequestCount",
      key: "activeDueDiligenceRequestCount",
    },
    {
      title: "Broadcast Requests",
      dataIndex: "activeBroadcastRequestCount",
      key: "activeBroadcastRequestCount",
    },
    {
      ...BASE_SORT_COLUMN_DESCEND,
      title: "Total",
      dataIndex: "totalCount",
      key: "totalCount",
      defaultSortOrder: SORT_ORDER.DESCEND,
      sorter: (a, b) => a.totalCount - b.totalCount,
    },
  ];

  useEffect(() => {
    getActiveRequestsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={isLoading}
      rowKey={(item) => item.profileId}
      rowSelection={false}
      size="middle"
    />
  );
}

export default ProfilesActiveRequests;
