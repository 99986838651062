import React from "react";

import { Box } from "../Box";

function ListBoxList({ children, ref, ...props }) {
  return (
    <Box
      as="ul"
      css={`
        list-style: none;
        user-select: none;
      `}
      ref={ref}
      role="listbox"
      tabIndex={-1}
      {...props}
    >
      {children}
    </Box>
  );
}

export { ListBoxList };
