import { Flex, Table } from "antd";
import React from "react";

import { Button, FormatText, NavigateLink } from "@gfw/corvus";

import buildDataSource from "./overviewBuild";

function OverviewMerge({ profileToRemove, profileToKeep, nextStep }) {
  const columns = [
    {
      title: "Stat",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Will be removed after merge",
      dataIndex: profileToRemove.id,
      key: profileToRemove.id,
    },
    {
      title: "Will be kept",
      dataIndex: profileToKeep.id,
      key: profileToKeep.id,
    },
  ];

  return (
    <Flex gap={16} vertical>
      <FormatText fs="md">Overview</FormatText>
      <Table
        columns={columns}
        dataSource={buildDataSource(profileToRemove, profileToKeep)}
        pagination={false}
        rowKey="name"
        size="small"
      />
      <Flex justify="space-around">
        <NavigateLink
          to={`/profiles/merge/${profileToKeep.id}/${profileToRemove.id}`}
        >
          Switch profiles
        </NavigateLink>
        <Button
          onClick={nextStep}
          style={{ width: 150, marginRight: 15 }}
          type="primary"
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

export default OverviewMerge;
