import { Table, Tag } from "antd";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import React from "react";

import { Loading, NavigateLink } from "@gfw/corvus";
import { profileStateToString, ProfileTypes, ROLES } from "@gfw/core";
import { selectorUsersByUserOIdWithProfiles } from "@gfw/backend-connector";

export default function UserProfilesList() {
  const { id } = useParams();
  const user = useSelector(selectorUsersByUserOIdWithProfiles(id));

  if (!user || user.isLoading) return <Loading />;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "NAME",
      render: (name, item) => {
        return (
          <NavigateLink noPadding to={`/profiles/${item._id}/details`}>
            {name}
          </NavigateLink>
        );
      },
    },
    {
      title: "Types",
      dataIndex: "types",
      key: "TYPES",
      render: (types) => {
        if (!types) return "";
        return ProfileTypes.getNamesFromIds(types).join(", ");
      },
    },
    {
      title: "Profile state",
      dataIndex: "state",
      key: "STATE",
      render: (state) => profileStateToString(state),
    },
    {
      title: "Role",
      dataIndex: "_id",
      key: "ROLE",
      render: (profileOId) => {
        const profileUser = user?.profileUsers?.find(
          (profileUser) => profileUser.profileOId === profileOId,
        );
        if (!profileUser) return "-";
        const { role } = profileUser;
        let color = "grey";
        if (role === ROLES.READONLY) {
          color = "red";
        } else if (role === ROLES.REGULAR) {
          color = "blue";
        } else if (role === ROLES.FULL) {
          color = "green";
        } else if (role === ROLES.ADMIN_ROLE) {
          color = "purple";
        }
        return <Tag color={color}>{ROLES[role]}</Tag>;
      },
    },
    {
      title: "Access",
      dataIndex: "_id",
      key: "PROFILEUSERSTATE",
      render: (profileOId) => {
        const profileUser = user?.profileUsers?.find(
          (profileUser) => profileUser.profileOId === profileOId,
        );
        let color = "green";
        let label = "ON";
        if (!profileUser?.isActive) {
          color = "red";
          label = "OFF";
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={user.profiles}
      loading={user.isLoading}
      pagination={false}
      rowKey="name"
      size="small"
    />
  );
}
