import { get } from "lodash";
import React from "react";
import ResizeObserver from "resize-observer-polyfill";

function useResizeObserver(ref, property) {
  const [dimensions, setDimensions] = React.useState({});

  // TODO: optimize this by not recalc if the value of the prop we wan't hasn't changed
  React.useLayoutEffect(
    function () {
      if (
        typeof ref !== "object" ||
        ref === null ||
        !(ref.current instanceof Element)
      ) {
        return;
      }

      const element = ref.current;

      const observer = new ResizeObserver(function (entries) {
        if (!Array.isArray(entries)) {
          return;
        }

        // Since we only observe the one element, we don't need to loop over the array
        if (!entries.length) {
          return;
        }

        const entry = entries[0];

        const key = property.split(".").pop();
        setDimensions({
          [key]: get(entry, property),
        });
      });

      observer.observe(element);

      return function unsubscribe() {
        observer.unobserve(element);
      };
    },
    [ref, property],
  );

  return React.useMemo(() => ({ ...dimensions }), [dimensions]);
}

export { useResizeObserver };
