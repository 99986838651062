import React from "react";

import { Text } from "../Text";
import { useFormControl } from "./FormControl";

function FormControlLabel({ children, htmlFor, ref, ...props }) {
  const formControl = useFormControl(props);

  return (
    <Text
      as="label"
      cursor="default"
      display="inline-block"
      htmlFor={htmlFor || formControl.id}
      ref={ref}
      typography="form-label"
      {...props}
    >
      {children}
    </Text>
  );
}

export { FormControlLabel };
