import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import qs from "qs";
import React, { useEffect } from "react";

import { callbackManager, userLogout } from "@gfw/backend-connector";
import { FormatText } from "@gfw/corvus";

export default function Logout() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const { redirect = "/" } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    const forceLogout = () => {
      localStorage.clear();
      window.location.assign(redirect);
    };
    const callbackId = callbackManager.add({
      onSuccess: forceLogout,
      onError: forceLogout,
    });
    dispatch(userLogout({ callbackId }));
  }, [dispatch, navigate, search]);

  return <FormatText>Redirecting...</FormatText>;
}
