import { Input, List } from "antd";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Loading, Modal, useThrottledValue } from "@gfw/corvus";

import { apiClient } from "@gfw/backend-connector";
import { isNil } from "lodash";
import { UserSearchResult, UserSearchResultAction } from "./UserSearch";

const StyledInput = styled(Input)`
  width: 50%;
`;

function AddExistingUserAction({
  disabled,
  getProfile,
  onActionCompleted,
  profileId,
}) {
  const [query, setQuery] = useState("");

  const throttledTerm = useThrottledValue(query, 300);

  const {
    data: users,
    mutateAsync: getUsers,
    isLoading,
  } = useMutation({
    mutationFn: () =>
      apiClient.get(`/users`, {
        params: {
          query,
          limit: 10,
          select: "_id username name status",
        },
      }),
    onSuccess: (response) => {
      return response.data;
    },
  });

  useEffect(() => {
    if (!isNil(query)) getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttledTerm]);

  return (
    <Modal
      footer={null}
      onCancel={getProfile}
      title="Add existing user without invite"
      trigger={
        <Button
          confirmCancelText="Ok"
          confirmOkText="Don't care"
          confirmTitle="No no, plz use invite instead"
          disabled={disabled}
        >
          Add Existing User
        </Button>
      }
    >
      <StyledInput
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Find users..."
        value={query}
      />
      {isLoading && <Loading size="small" />}
      {!!users && (
        <List
          dataSource={users?.data}
          locale={{ emptyText: "Sorry, no users that matched that query." }}
          renderItem={(user) => (
            <List.Item>
              <UserSearchResult user={user} />
              <UserSearchResultAction
                onUserAddedToProfile={onActionCompleted}
                profileId={profileId}
                user={user}
              />
            </List.Item>
          )}
          split={false}
        />
      )}
    </Modal>
  );
}

export { AddExistingUserAction };
