import React from "react";

import ChoiceField from "./ChoiceField";
import MultipleChoiceField from "./MultipleChoiceField";

function ChoiceQuestion({ isMultipleChoice, ...props }) {
  if (isMultipleChoice) {
    return <MultipleChoiceField {...props} />;
  } else {
    return <ChoiceField {...props} />;
  }
}

export default ChoiceQuestion;
