import React from "react";

import { warning } from "@gfw/core";

import { Box } from "../Box";

function Stack({ children, spacing = "sm", ref, ...props }) {
  warning(
    "layout" in props,
    `[@gfw:orion] Stack no longer has support for layout="${props.layout}". Consider using the Inline or FlexBox instead.`,
  );
  // TODO: Grid currently isn't a Box so that why we use Box directly but really, it doesn't matter
  return (
    <Box display="grid" ref={ref} row-gap={spacing} {...props}>
      {children}
    </Box>
  );
}

export { Stack };
