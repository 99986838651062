import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import styled from "styled-components";

import { apiClient } from "@gfw/backend-connector";
import { BrowserTests, Loading } from "@gfw/corvus";

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

function UserBrowserTests() {
  const { id } = useParams();
  const { data: browserTests = {}, isLoading } = useQuery({
    queryKey: ["newsProviders"],
    queryFn: async () => {
      const response = await apiClient.get(`/users/${id}/tests`);
      if (response?.data) {
        return response.data;
      }
      return {};
    },
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <Loading />;
  const { browser, os, tests, appVersion, createdAt } = browserTests || {};

  return (
    <>
      <Title>General information</Title>
      <p>
        Browser : {browser?.name ?? ""} {browser?.version ?? ""}
      </p>
      <p>
        OS : {os?.name ?? ""} {os?.version ?? ""}
      </p>
      <p>AppVersion : {appVersion}</p>
      <p>Date: {createdAt}</p>
      <BrowserTests tests={tests} />
    </>
  );
}

export default UserBrowserTests;
