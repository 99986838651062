import { get, merge } from "lodash";
import React from "react";

import { warning } from "@gfw/core";

import { Box } from "../Box";
import { getSize } from "../../style";
import { useFormControl } from "../FormControl";

const INPUT_VARIANTS = {
  outline: {
    border: true,
    bg: "transparent",
    pseudo: {
      hover: {
        border: { color: "gray.400" },
      },
      focus: {
        border: { color: "gfw.700" },
      },
      disabled: {
        px: 0,
        bg: "transparent",
        border: false,
      },
      "read-only": {
        px: 0,
        bg: "transparent",
        border: false,
        "user-select": "all",
      },
      invalid: {
        border: { color: "error" },
      },
    },
  },
  unstyled: {
    bg: "transparent",
    border: "none",
  },
};

function getSafeVariant(variant) {
  warning(
    ["standard", "outlined"].includes(variant),
    `[@gfw:orion] Input no longer has support for variant "${variant}".`,
  );

  return get(INPUT_VARIANTS, variant, INPUT_VARIANTS.outline);
}

function getInputStyle({ variant, ...props }) {
  return merge({}, getSafeVariant(variant), props);
}

function Input({ variant = "outline", as = "input", ref, ...props }) {
  warning(
    "label" in props,
    `[@gfw:orion] Input no longer has support for "label". Please remove it.`,
  );
  const formControl = useFormControl(props);

  const inputStyleProps = getInputStyle({
    variant,
  });

  return (
    <Box
      align-items="center"
      aria-disabled={formControl.isDisabled}
      aria-invalid={Boolean(formControl.isInvalid)}
      aria-readonly={formControl.isReadOnly}
      as={as}
      css={`
        appearance: none;
        && {
          outline: none;
          filter: none;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
          -webkit-transition-delay: 9999s;
        }
        &:-moz-autofill,
        &:-moz-autofill-preview {
          filter: none;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `}
      data-orion-element="input"
      disabled={formControl.isDisabled || undefined}
      display="flex"
      font-weight="regular"
      fs="12px"
      id={formControl.id}
      max-width="100%"
      min-width={getSize("xs")}
      name={formControl.name}
      readOnly={formControl.isReadOnly}
      ref={ref}
      {...inputStyleProps}
      {...props}
      height="32px"
      px="8px"
      transition="all short"
      type="text"
    />
  );
}

Input.OrionComponent = "Input";

export { Input, getInputStyle };
