import { Table, Tag } from "antd";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import React from "react";
import ReactJson from "react-json-view";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Loading,
  Modal,
  NavigateLink,
} from "@gfw/corvus";
import { NOTIFICATION_STATUS, SUBSCRIPTIONS_TYPES } from "@gfw/core";
import { selectorUsersByOId } from "@gfw/backend-connector";
import { uniqBy } from "lodash";

const Info = styled.div`
  margin-bottom: 20px;
`;

const formatDate = (dateObj) => {
  if (!dateObj) return "-";
  const [date, time] = new Date(dateObj).toISOString().split("T");
  const [hour, min] = time.split(":");
  return `${date} ${hour}:${min}`;
};

export default function UserNotifications() {
  const { id } = useParams();
  const user = useSelector(selectorUsersByOId(id));

  if (!user || user.isLoading) return <Loading />;
  const profilesFilters = user.profiles?.map((p) => ({
    text: p.name,
    value: p._id,
  }));
  const statusFilters = uniqBy(
    user.notifications?.map((n) => ({
      text: n.status === 200 ? "SENT" : NOTIFICATION_STATUS[n.status],
      value: n.status,
    })),
    "value",
  );
  const subscriptionTypeFilters = uniqBy(
    user.notifications?.map((n) => ({
      text: SUBSCRIPTIONS_TYPES[n.subscriptionType],
      value: n.subscriptionType,
    })),
    "value",
  );

  const antdColumns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: statusFilters,
      ellipsis: true,
      width: 160,
      onFilter: (value, record) => record.status === value,
      render: (value, record) => {
        let color = "green";
        let label = NOTIFICATION_STATUS[value];
        if (record.isSpammed) {
          color = "magenta";
          label = "SPAMMED";
        } else if (record.isBlocked) {
          color = "red";
          label = "BLOCKED";
        } else if (record.isBounced) {
          color = "orange";
          label = "BOUNCED";
        } else if (value === NOTIFICATION_STATUS.NOT_SUBSCRIBED) {
          color = "blue";
        } else if (value === NOTIFICATION_STATUS.SUCCESS) {
          label = "SENT";
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      title: "SentAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => {
        if (!value) return;
        return formatDate(value);
      },
      width: 150,
    },
    {
      title: "profile",
      dataIndex: "profileOId",
      filters: profilesFilters,
      key: "profileOId",
      ellipsis: true,
      onFilter: (value, record) => record.profileOId === value,
      render: (value, record) => {
        if (!value) return "-";
        return (
          <NavigateLink noPadding to={`/profiles/${value}/details`}>
            {record?.profileName || "unknown"}
          </NavigateLink>
        );
      },
      width: 160,
    },
    {
      title: "subject",
      dataIndex: "subject",
      key: "subject",
      ellipsis: true,
    },
    {
      title: "subscriptionType",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
      ellipsis: true,
      width: 200,
      filters: subscriptionTypeFilters,
      render: (value) => {
        if (!value) return "-";
        return SUBSCRIPTIONS_TYPES[value];
      },
    },
    {
      title: "actions",
      dataIndex: "actions",
      key: "actions",
      width: 80,
      render: (_, notif) => {
        return (
          <Modal
            hideFooterBorder
            hideHeaderBorder
            title="Notification"
            trigger={
              <Button icon="eye" noPadding type="link">
                View
              </Button>
            }
          >
            <ReactJson
              collapsed={1}
              displayDataTypes={false}
              name="Details"
              src={notif}
            />
          </Modal>
        );
      },
    },
  ];

  return (
    <>
      <Info>List of the notifications sent to this user</Info>

      <Table
        columns={antdColumns}
        dataSource={user.notifications || []}
        pagination={false}
        rowKey={(item) => item._id}
      />
    </>
  );
}
