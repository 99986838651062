import { Card, Table, Tag } from "antd";
import { saveAs } from "file-saver";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  FONT_WEIGHTS,
  FormatText,
  NavigateLink,
  Space,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import {
  BASE_SORT_COLUMN,
  BASE_SORT_COLUMN_DESCEND,
  datetime,
  normalizeString,
  PROFILE_STATE,
  profileStateToString,
} from "@gfw/core";

const StyledSpace = styled(Space)`
  margin-bottom: 16px;
`;
const StyledName = styled.div`
  font-weight: ${FONT_WEIGHTS.bold};
`;

const formatDate = (dateObj) => {
  if (!dateObj) return "-";
  const [date, time] = new Date(dateObj).toISOString().split("T");
  const [hour, min] = time.split(":");
  return `${date} ${hour}H${min}`;
};

// This page is shown at /profiles
function ProfilesList() {
  const { data: profiles, isLoading } = useQuery({
    queryKey: ["profiles"],
    queryFn: async () => {
      const response = await apiClient.get(`/profiles`);
      return response.data;
    },
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: downloadAllProfiles, isLoading: isDownloading } =
    useMutation({
      mutationFn: () => {
        return apiClient.get("/admin/biggest-profiles", {
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "blob",
        });
      },
      onSuccess: (response) => {
        const file = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(file, `GFW-Profiles-${datetime.iso8601Date()}.xlsx`, {
          autoBom: true,
        });
      },
      onError: __DEPRECATED__ErrorHandler,
    });

  const columns = [
    {
      ...BASE_SORT_COLUMN,
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, profile) => (
        <NavigateLink noPadding to={`/profiles/${profile?.profileId}/details`}>
          <StyledName>{name}</StyledName>
        </NavigateLink>
      ),
      sorter: (a, b) =>
        normalizeString(a.name).localeCompare(normalizeString(b.name)),
    },
    {
      ...BASE_SORT_COLUMN,
      dataIndex: "types",
      title: "Types",
      key: "types",
      render: (types) => types.join(", "),
      sorter: (a, b) =>
        a.types[0].toString().localeCompare(b.types[0].toString()),
    },
    {
      ...BASE_SORT_COLUMN,
      dataIndex: "location",
      title: "Location",
      key: "location",
      sorter: (a, b) =>
        a.location.toString().localeCompare(b.location.toString()),
    },
    {
      ...BASE_SORT_COLUMN,
      dataIndex: "lastConnectionAt",
      title: "LastConnectionAt",
      key: "lastConnectionAt",
      render: (value) => formatDate(value),
      sorter: (a, b) =>
        dayjs(a.lastConnectionAt).isAfter(dayjs(b.lastConnectionAt)) ? 1 : -1,
    },
    {
      ...BASE_SORT_COLUMN,
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state) => {
        //unknown state
        let color = "grey";
        let label = profileStateToString(state);
        if (state === PROFILE_STATE.DRAFT) {
          color = "blue";
        } else if (state === PROFILE_STATE.PROPOSED) {
          color = "purple";
        } else if (state === PROFILE_STATE.INACTIVE) {
          color = "red";
        } else if (state === PROFILE_STATE.OFFICIAL) {
          color = "green";
        }
        return <Tag color={color}>{label}</Tag>;
      },
      sorter: (a, b) => a.state - b.state,
    },
    {
      ...BASE_SORT_COLUMN,
      dataIndex: "isPremium",
      title: "Is Premium",
      key: "isPremium",
      render: (isPremium) => isPremium && <Tag>{String(isPremium)}</Tag>,
      sorter: (a, b) =>
        a.isPremium.toString().localeCompare(b.isPremium.toString()),
    },
    {
      ...BASE_SORT_COLUMN_DESCEND,
      dataIndex: "activeCounterpartyCount",
      title: "Counterparties",
      key: "activeCounterpartyCount",
      defaultSortOrder: "descend",
      render(counterpartiesCount) {
        if (!counterpartiesCount) return null;
        return <FormatText>{String(counterpartiesCount)}</FormatText>;
      },
      sorter: (a, b) => a.activeCounterpartyCount - b.activeCounterpartyCount,
    },
  ];

  return (
    <Card>
      <StyledSpace>
        <NavigateLink icon="add" noPadding to="/profiles/new">
          New Profile
        </NavigateLink>
        <Button
          icon="download"
          loading={isDownloading}
          onClick={downloadAllProfiles}
          type="primary"
        >
          Download All Profiles
        </Button>
      </StyledSpace>
      <Table
        columns={columns}
        dataSource={profiles}
        loading={isLoading}
        rowKey={(item) => item._id}
      />
    </Card>
  );
}

export default ProfilesList;
