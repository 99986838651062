import { Flex, Switch, Typography } from "antd";
import { Observer, useLocalObservable } from "mobx-react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import gql from "graphql-tag";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  Grid,
  Loading,
  notification,
  Space,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import { NewsArticleForm } from "@src/resources/News/components";
import { newsArticleModel } from "@src/resources/News/utils";
import { Page, PageHeader } from "@src/resources/generic/components";

const { Text } = Typography;

const NEWS_ARTICLE_QUERY = gql`
  query NewsArticle($id: ID!) {
    NewsArticle(id: $id) {
      id
      title
      subtitle
      publishedAt
      hidden
      link
      type
      provider {
        id
        name
      }
    }
  }
`;

function EditNewsArticlePage() {
  const { id } = useParams();

  const article = useLocalObservable(() => newsArticleModel);

  const { isLoading } = useQuery({
    queryKey: ["newsArticle"],
    queryFn: async () => {
      const response = await apiClient.graphql(NEWS_ARTICLE_QUERY, {
        variables: { id },
      });

      if (response?.NewsArticle) {
        const { NewsArticle } = response;
        const articleData = {
          title: NewsArticle.title,
          subtitle: NewsArticle.subtitle,
          link: NewsArticle.link,
          type: NewsArticle.type,
          hidden: NewsArticle.hidden,
          provider: NewsArticle.provider,
          publishedAt: NewsArticle.publishedAt,
        };
        article.updateAll(articleData);
        return articleData;
      } else {
        return { error: "error" };
      }
    },
    refetchOnWindowFocus: false,
  });

  const updateNewsArticleHandler = async () => {
    try {
      await apiClient.patch(`/news/articles/${id}`, article.getSnapshot());
      notification.success({ message: `The article has been updated!` });
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <PageHeader iconType="news">Edit News Article</PageHeader>
      <Card>
        <Grid.Row gutter={64}>
          <Grid.Col flex={4}>
            <NewsArticleForm article={article} isEditing={true} />
            <Observer>
              {() => (
                <Button
                  disabled={article.invalid}
                  mt="lg2"
                  onClick={updateNewsArticleHandler}
                  type="primary"
                >
                  Update
                </Button>
              )}
            </Observer>
          </Grid.Col>
          <Grid.Col flex={1}>
            <Space direction="vertical" size="small">
              <Text>Actions</Text>
              <Flex align="center" gap="small">
                <Switch
                  checked={article.hidden}
                  onChange={() => {
                    article.update("hidden", !article.hidden);
                    updateNewsArticleHandler();
                  }}
                  size="small"
                />
                <Text>Hide</Text>
              </Flex>
            </Space>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </Page>
  );
}

export { EditNewsArticlePage };
