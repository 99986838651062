import { useQuery } from "@tanstack/react-query";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Card, FormatText, Grid } from "@gfw/corvus";

import { StyledFlex } from "./DueDiligenceAnalytics.styles";

export function DueDiligenceAnalytics() {
  const { data, isLoading } = useQuery({
    queryKey: ["due-diligence-analytics"],
    queryFn: () => {
      return apiClient.get("/admin/due-diligence-count");
    },
  });

  return (
    <Card
      isLoading={isLoading}
      size="small"
      title={
        <StyledFlex>
          <FormatText fs="md">Due Diligence</FormatText>
          <FormatText fs="md">Count</FormatText>
        </StyledFlex>
      }
    >
      <Grid.Row gutter={[0, 16]}>
        <Grid.Col span={24}>
          <StyledFlex>
            <FormatText>Total Campaigns</FormatText>
            <FormatText>{data?.data?.totalCampaigns}</FormatText>
          </StyledFlex>
        </Grid.Col>
        <Grid.Col span={24}>
          <StyledFlex>
            <FormatText>Total Completed Campaigns</FormatText>
            <FormatText>{data?.data?.totalCompletedCampaigns}</FormatText>
          </StyledFlex>
        </Grid.Col>
        <Grid.Col span={24}>
          <StyledFlex>
            <FormatText>Total Requests</FormatText>
            <FormatText>{data?.data?.totalRequests}</FormatText>
          </StyledFlex>
        </Grid.Col>
        <Grid.Col span={24}>
          <StyledFlex align="center" justify="space-between">
            <FormatText>Total Reports</FormatText>
            <FormatText>{data?.data?.totalReports}</FormatText>
          </StyledFlex>
        </Grid.Col>
        <Grid.Col span={24}>
          <StyledFlex align="center" justify="space-between">
            <FormatText>Total Completed Reports</FormatText>
            <FormatText>{data?.data?.totalCompletedReports}</FormatText>
          </StyledFlex>
        </Grid.Col>
      </Grid.Row>
    </Card>
  );
}
