import { isEqual } from "lodash";
import React, { Children, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Box } from "../Box";
import { Button } from "../Button";
import { FlexBox } from "../FlexBox";
import { Icon } from "../Icon";
import { Input, InputGroup } from "../Input";
import { ListBox } from "../ListBox";

const StyledButton = styled(Button)`
  & button {
    color: #9e9e9e;
    display: flex;
    align-items: center;
  }
  & button:hover {
    color: #757575;
  }
`;

function useMultiSelect({ onSelect, value, defaultValue = [] }) {
  const [selection, updateSelection] = useState(value || defaultValue);

  // add a new value
  const onChangeHandler = useCallback(
    (newValue) => {
      let newSelection = [...selection, newValue];
      updateSelection(newSelection);
      onSelect && onSelect(newSelection);
    },
    [onSelect, selection],
  );

  const onRemoveHandler = useCallback(
    (option) => {
      let newSelection = selection.filter((value) => !isEqual(value, option));
      updateSelection(newSelection);
      onSelect && onSelect(newSelection);
    },
    [onSelect, selection],
  );

  return useMemo(
    () => ({
      selection,
      onChange: onChangeHandler,
      onRemove: onRemoveHandler,
    }),
    [onChangeHandler, onRemoveHandler, selection],
  );
}

function SelectedOptions({ options, onRemoveSelectedOption, optionToString }) {
  const onRemoveSelectedOptionHandler = (option) => (event) => {
    // stops the event from bubbling up to the ListBoxButton
    event.stopPropagation();
    onRemoveSelectedOption(option);
  };

  return (
    <FlexBox align="center" overflow="hidden" wrap="wrap">
      {options.map((option, index) => (
        <FlexBox
          align="center"
          bg="gray.200"
          border-radius="2px"
          key={index}
          line-height="normal"
          m="2px"
          min-width="0px"
          px="6px"
          py="3px"
        >
          <Box word-break="truncate">{optionToString(option)}</Box>
          <StyledButton
            height="12px"
            icon="close"
            iconFs={12}
            ml="6px"
            noPadding
            onClick={onRemoveSelectedOptionHandler(option)}
            width="12px"
          />
        </FlexBox>
      ))}
    </FlexBox>
  );
}

function MultiSelectInput({ children, size = "md", width, ref, ...props }) {
  return (
    <InputGroup
      cursor="pointer"
      height="auto"
      max-width="fit-content"
      min-height="32px"
      min-width={width || size}
      outline="none"
      ref={ref}
      variant="outline"
      {...props}
    >
      <Input as="div">{children}</Input>
      <Icon fs="1.2em" mr="8px">
        caret-down
      </Icon>
    </InputGroup>
  );
}

function MultiSelect({
  children,
  placeholder,
  onSelect,
  value,
  defaultValue,
  optionToString = (v) => String(v),
  onBlur,
  size,
  ref,
  ...props
}) {
  const { onChange, onRemove, selection } = useMultiSelect({
    onSelect,
    value,
    defaultValue,
  });

  const options = Children.toArray(children).filter((option) => {
    // an option has a value
    // our selection contain these values
    // by combining these we'll figure out if we have selected the option already and removed that from the presented options
    return selection.some((value) => isEqual(option.props.value, value))
      ? false
      : true;
  });

  return (
    <ListBox onChange={onChange} value={selection}>
      <ListBox.Button
        as={MultiSelectInput}
        onBlur={onBlur}
        ref={ref}
        size={size}
        {...props}
      >
        {selection.length > 0 ? (
          <SelectedOptions
            onRemoveSelectedOption={onRemove}
            options={selection}
            optionToString={optionToString}
          />
        ) : (
          placeholder
        )}
      </ListBox.Button>
      <ListBox.Overlay>
        <ListBox.List>{options}</ListBox.List>
      </ListBox.Overlay>
    </ListBox>
  );
}

MultiSelect.Option = ListBox.Option;

export { MultiSelect };
