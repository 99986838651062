import { useMutation } from "@tanstack/react-query";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Button, Modal, notification } from "@gfw/corvus";

function RemoveInviteAction({ onActionCompleted, user }) {
  const { mutateAsync: removeInvite } = useMutation({
    mutationFn: () => apiClient.delete(`/invites/${user.inviteId}`),
    onSuccess: () => {
      notification.success({
        message: `Invite to ${user.username} was successfully deleted`,
      });
      onActionCompleted();
    },
    onError: () => {
      notification.error({
        message: "Oops, something went wrong",
        description: `An error occurred while trying to delete the invite to ${user.username}`,
      });
    },
  });

  return (
    <Modal
      hideFooterBorder
      hideHeaderBorder
      onOk={removeInvite}
      title="Delete invite"
      trigger={
        <Button danger icon="delete">
          Delete
        </Button>
      }
    >
      {`Are you sure that you want to delete the invite to ${user.username}?`}
    </Modal>
  );
}

export { RemoveInviteAction };
