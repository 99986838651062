import { CopyToClipboard } from "react-copy-to-clipboard";
import { Flex, Select, Table } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Loading,
  Modal,
  NavigateLink,
  notification,
  Space,
  StateTag,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { getEntries, PROFILE_STATE, USER_ROLE } from "@gfw/core";

import {
  AddExistingUserAction,
  InviteUserAction,
  RemoveInviteAction,
  RemoveUserFromProfileAction,
} from "./actions";

const { Option } = Select;

const SubText = styled("div")`
  font-size: 10px;
  color: grey;
`;

function CopyInviteUrl({ inviteUrl }) {
  return (
    <CopyToClipboard
      onCopy={() => notification.success({ message: "Copied to clipboard" })}
      text={inviteUrl}
    >
      <Button icon="copy" tooltip="Copy to clipboard">
        Copy Link
      </Button>
    </CopyToClipboard>
  );
}

function RenderRole({ user, profile }) {
  const [showModal, setShowModal] = useState(false);
  const isInvited = user.status === "PENDING";

  const { mutateAsync: updateUserRole } = useMutation({
    mutationFn: () => {
      return apiClient.patch(`/profiles/${profile._id}/users/${user._id}`, {
        role: userRole,
      });
    },
    onSuccess: () => {
      notification.success({
        message: `User ${user.username} role has been updated`,
      });
      setShowModal(false);
    },
    onError: () => {
      notification.error({
        message: "Oops, something went wrong",
        description: `There was an error while trying to update the role for the user ${user.email}, please try again or contact support`,
      });
      setShowModal(false);
    },
  });

  const [userRole, setUserRole] = useState(user.role);

  return (
    <>
      <Modal
        hideFooterBorder
        hideHeaderBorder
        okText="Confirm"
        onCancel={() => {
          setUserRole(user.role);
          setShowModal(false);
        }}
        onOk={updateUserRole}
        title="Update User Role"
        visible={showModal}
      >
        Do you wish to update {user.email} role to {userRole}?
      </Modal>
      <Select
        defaultValue={userRole}
        disabled={isInvited}
        onSelect={(role) => {
          try {
            setShowModal(true);
            setUserRole(role);
          } catch (error) {
            setUserRole(user.role);
            notification.error({
              message: "Oops, something went wrong",
              description:
                "There was an error while trying to update the role of the user",
            });
          }
        }}
        style={{ width: "100%" }}
        value={userRole}
        variant="borderless"
      >
        {getEntries(USER_ROLE).map(([key, value]) => {
          return (
            <Option key={key} value={value}>
              {key}
            </Option>
          );
        })}
      </Select>
    </>
  );
}

function ProfileUsers({ profile, state }) {
  const isOfficial = state === PROFILE_STATE.OFFICIAL;

  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["profileUsers", profile._id],
    queryFn: async () => {
      const response = await apiClient.get(
        `/profiles/${profile._id}/admin/users`,
      );
      return response.data;
    },
    refetchOnWindowFocus: false,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, user) => {
        if (!name) {
          return "-";
        }
        return (
          <NavigateLink noPadding to={`/users/${user._id}/read`}>
            {name.first} {name.last}
          </NavigateLink>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "email",
      render: (email, user) => {
        const { status, expirationDate } = user;
        const isInvited = status === "PENDING";
        return (
          <Flex vertical>
            {email}
            {isInvited && <SubText>expires {expirationDate}</SubText>}
          </Flex>
        );
      },
    },
    {
      title: "Invited By",
      dataIndex: "invitedBy",
      key: "invitedBy",
      render: (invitedBy) => {
        if (invitedBy) {
          return invitedBy.isGFWAdmin
            ? `${invitedBy.email} (admin)`
            : invitedBy.email;
        }
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_, user) => {
        return <RenderRole profile={profile} user={user} />;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <StateTag.UserState label={status.toUpperCase()} state={status} />
        );
      },
    },
    {
      render: (_, user) => {
        const { _id, inviteUrl, status } = user;
        const isInvited = status === "pending";
        const renderActions = isInvited ? (
          <Space>
            <CopyInviteUrl inviteUrl={inviteUrl} />
            <RemoveInviteAction
              onActionCompleted={() => refetch()}
              user={user}
            />
          </Space>
        ) : (
          <RemoveUserFromProfileAction
            onActionCompleted={() => refetch()}
            profileOId={profile._id}
            user={user}
          />
        );
        return <>{renderActions}</>;
      },
    },
  ];

  return (
    <Flex gap={16} vertical>
      <Space>
        <InviteUserAction
          onActionCompleted={() => refetch()}
          profile={profile}
        />
        <AddExistingUserAction
          disabled={!isOfficial}
          getProfile={() => refetch()}
          onActionCompleted={() => {
            notification.success({ message: `User was added to profile` });
          }}
          profileId={profile.profileId}
        />
      </Space>
      {isLoading && <Loading />}
      <Table
        columns={columns}
        dataSource={users}
        loading={isLoading}
        rowKey={(item) => item._id || item.inviteId}
        rowSelection={false}
        size="middle"
      />
    </Flex>
  );
}

export default ProfileUsers;
