import { get } from "lodash";
import React from "react";

import { FormControlText } from "./FormControlText";
import { useFormControl } from "./FormControl";

function FormControlErrorFeedback({ children, ref, ...props }) {
  const formControl = useFormControl(props);

  if (Boolean(formControl.isInvalid) === false) {
    return null;
  }

  // try to extract a validation error (yup) if that's supplied
  let message = get(formControl.isInvalid, "errors", children);
  // children always wins anyway if that's supplied
  if (children) {
    message = children;
  }

  return (
    <FormControlText
      color="error"
      font-size="10px"
      id={formControl.id ? `${formControl.id}-error-message` : null}
      ref={ref}
      {...props}
    >
      {message}
    </FormControlText>
  );
}

function FormControlFeedback({ children, type, ref, ...props }) {
  if (type === "error") {
    return (
      <FormControlErrorFeedback {...props}>{children}</FormControlErrorFeedback>
    );
  }

  return (
    <FormControlText ref={ref} {...props}>
      {children}
    </FormControlText>
  );
}

export { FormControlFeedback };
