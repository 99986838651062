import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";

import { apiClient } from "@gfw/backend-connector";
import { Banner } from "@gfw/corvus";
import { BANNER_TYPE } from "@gfw/core";

function WarningBanner() {
  const hiddenBannerOIds =
    JSON.parse(localStorage.getItem("hiddenBannerOIds")) || [];

  const { mutateAsync: getActiveBanners, data: banner = {} } = useMutation({
    mutationFn: async () => {
      const response = await apiClient.get(
        `/banners/active/${BANNER_TYPE.ALERT}`,
      );
      return response.data;
    },
  });

  const onClose = () => {
    localStorage.setItem(
      "hiddenBannerOIds",
      JSON.stringify([...hiddenBannerOIds, banner._id]),
    );
  };

  useEffect(() => {
    getActiveBanners();
  }, [getActiveBanners]);

  if (hiddenBannerOIds.includes(banner?._id?.toString())) {
    return null;
  }

  return <Banner banner={banner} onClose={onClose} />;
}

export { WarningBanner };
