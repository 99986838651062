/* eslint-disable react/no-unknown-property */

import { Card as AntdCard, Flex } from "antd";
import { Portal } from "reakit/Portal";
import React from "react";
import styled from "styled-components";

import { getColor } from "../../style";
import { Icon } from "../Icon";
import { Loading } from "../Loading";
import { Text } from "../Text";

const ErrorCard = styled(AntdCard)`
  border-left: 3px solid ${getColor("error")};
  background: ${getColor("red.60")};
  color: ${getColor("text.on.light")};
  border-radius: 0.25rem;
`;

const ErrorIcon = styled(Icon)`
  font-size: 1.5em;
  align-self: flex-start;

  &:first-child {
    margin-right: 0.5rem;
  }
`;

const ErrorBackdrop = styled("div")`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;

  &::after {
    content: "";
    background: hsla(0, 100%, 100%, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

function NetworkErrors({ error }) {
  if (error?.result?.errors?.length) {
    return (
      <Text
        as="pre"
        css={`
          white-space: pre-line;
          overflow: hidden;
        `}
      >
        {JSON.stringify(
          error.result.errors.map((e) => ({
            [e.extensions?.code]: e.message,
          })),
          null,
          2,
        )}
      </Text>
    );
  }
  return null;
}

function GraphQLErrors({ errors }) {
  console.error("GraphQLErrors", errors);
  return (
    !!errors?.length && (
      <Text as="pre">
        {JSON.stringify(
          errors.map((e) => ({
            [e.extensions?.code]: e.message,
          })),
        )}
      </Text>
    )
  );
}

function GraphQLError({ error }) {
  if (error) {
    // TODO: in production this is an error that should be logged to AI for example.
    // TODO: better error message text, what should it say?? Should it also be a translated text?
    return (
      <Portal>
        <ErrorBackdrop>
          <div
            css={`
              max-width: 70vw;
              min-width: 30vw;
              margin: 30vh auto;
            `}
          >
            <ErrorCard>
              <Flex align="center">
                <ErrorIcon color="error" type="error" />
                <Flex vertical>
                  {error.message}
                  {__DEV__ && (
                    <>
                      <GraphQLErrors errors={error.graphQLErrors} />
                      <NetworkErrors error={error.networkError} />
                    </>
                  )}
                </Flex>
              </Flex>
            </ErrorCard>
          </div>
        </ErrorBackdrop>
      </Portal>
    );
  }
}

function GraphqlQueryResult({
  isReady,
  isLoading,
  hasErrors,
  getErrors,
  children,
}) {
  if (isLoading) {
    return <Loading />;
  } else if (hasErrors) {
    return <GraphQLError error={getErrors()} />;
  } else if (isReady) {
    return children;
  } else {
    return null;
  }
}

export { GraphQLError, GraphqlQueryResult };
