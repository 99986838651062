import { Route, Routes } from "react-router";
import React from "react";

import CustomQuestionnaireEdit from "./CustomQuestionnaireEdit";
import CustomQuestionnaireImport from "./CustomQuestionnaireImport";
import CustomQuestionnaireList from "./CustomQuestionnaireList";
import CustomQuestionnaireNew from "./CustomQuestionnaireNew";

// This is the root module for all the user routes (e.g. a route handler for /banner)
function Index() {
  return (
    <Routes>
      <Route element={<CustomQuestionnaireNew />} path="new" />
      <Route element={<CustomQuestionnaireImport />} path="import" />
      <Route element={<CustomQuestionnaireEdit />} path=":id/*" />
      <Route element={<CustomQuestionnaireList />} path="*" />
    </Routes>
  );
}

export default Index;
