import { omit } from "lodash";
import React from "react";

import { FlexBox } from "../FlexBox";
import { getInputStyle, Input } from "./Input";
import { getValidReactChildren } from "../../core";
import { useFormControl } from "../FormControl";

function isOrionInput(element) {
  return element.type.OrionComponent && element.type.OrionComponent === "Input";
}

function InputGroup({ children, variant, ref, ...props }) {
  const inputStyleProps = getInputStyle({
    variant,
    pseudo: {
      // we "steal" the focus border on the Input and place that on ourselves when the Input gets focused
      "focus-within": {
        border: { color: "gfw.700" },
      },
    },
  });

  const formControl = useFormControl(props);

  const validChildren = getValidReactChildren(children);

  const totalNumberOfChildren = React.Children.count(validChildren);

  const elements = React.Children.map(validChildren, (child, index) => {
    const isFirst = index === 0;
    const isLast = index === totalNumberOfChildren - 1;
    // a bit of a hack right now to get this working with ComboBoxInput ect
    if (isOrionInput(child)) {
      return React.cloneElement(child, {
        width: "100%",
        height: "100%",
        variant: "unstyled",
      });
    } else {
      // assumption right now...
      return React.cloneElement(child, {
        ...(isLast && !child.props.mr && { mr: "4px" }),
        ...(isFirst && !child.props.ml && { ml: "4px" }),
      });
    }
  });

  return (
    <FlexBox
      align="center"
      aria-disabled={formControl.isDisabled}
      aria-invalid={formControl.isInvalid}
      aria-readonly={formControl.isReadOnly}
      ref={ref}
      {...inputStyleProps}
      {...omit(Input.defaultProps, "as", "type", "px")}
      {...props}
    >
      {elements}
    </FlexBox>
  );
}

export { InputGroup };
